<template>
  <div class="info">
    <!-- <p class="tips">修改登录密码</p> -->
    <main :style="actions ? 'background:#f5f5f5' : ''">
      <template v-if="!actions">
        <van-cell title="修改支付密码" is-link @click="actions = 2" />
        <van-cell title="找回支付密码" is-link @click="actions = 1" />
      </template>

      <template v-if="actions === 1">
        <van-field v-model="mobile" type="tel" label="手机号" readonly />
        <van-field
          v-model="captcha"
          center
          clearable
          label="验证码"
          placeholder="请输入短信验证码"
          maxlength="6"
        >
          <template #button>
            <button v-if="codeShow" class="get_code" @click.prevent="getCode">
              发送验证码
            </button>
            <p v-else class="count_down">{{ codeCountDown }}s后重新获取</p>
          </template>
        </van-field>
      </template>
      <van-field
        v-model="psw"
        type="password"
        label="原密码"
        placeholder="请输入原密码"
        maxlength="6"
        v-if="actions === 2"
      />
      <van-field
        v-model="psw2"
        center
        type="password"
        label="新密码"
        placeholder="请输入新密码"
        maxlength="6"
        v-if="actions"
      >
      </van-field>

      <div class="button" v-show="actions">
        <van-button block round color="#009A4D" @click="submit"
          >确认</van-button
        >
      </div>
    </main>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "payment-password",
  computed: {
    ...mapState("user", ["userInfo", "electronicAcct"]),
  },
  data() {
    return {
      actions: 0,
      psw: "",
      psw2: "",
      mobile: "",
      captcha: "",
      codeShow: true, //验证码显示
      codeCountDown: 59, //验证码倒计时
    };
  },
  watch: {},

  async created() {
    await this.$store.dispatch("user/queryElectronicAcct");
    this.mobile = this.electronicAcct[0].mobileNumber;
  },

  methods: {
    submit() {
      if (this.actions === 1) {
        if (this.captcha.length < 6) {
          this.Toast.fail("请输入6位数验证码");
        } else if (/^\d+$/.test(this.paw2) || this.psw2.length < 6) {
          this.Toast.fail("请输入6位数数字密码");
        } else {
          this.resetPwd();
        }
      } else if (this.actions === 2) {
        if (this.psw === this.psw2) {
          this.Toast.fail("原密码和新密码一致");
        } else if (
          /^\d+$/.test(this.paw) ||
          /^\d+$/.test(this.paw2) ||
          this.psw.length < 6 ||
          this.psw2.length < 6
        ) {
          this.Toast.fail("请输入6位数数字密码");
        } else {
          this.updateAcctPwd();
        }
      }
    },
    async getCode() {
      this.Toast.loading({
        duration: 0,
        message: "发送中...",
        forbidClick: true,
      });
      await this.$API.user
        .sendSmsCodesByBalanceAcctId({
          object: {
            serviceType: "1",
            userId: this.userInfo.id,
          },
        })
        .then((res) => {
          this.Toast.clear();
          if (res.data.code != "SUCCESS") throw Error(res.data.msg);
          this.Toast.success("发送成功");
          this.codeShow = false;
          let t = setInterval(() => {
            this.codeCountDown--;
            if (this.codeCountDown <= 0) {
              this.codeShow = true;
              clearInterval(t);
              this.codeCountDown = 29;
            }
          }, 1000);
        })
        .catch((e) => {
          this.Toast.fail(e.message);
        });
    },
    async updateAcctPwd() {
      await this.$API.user
        .updateAcctPwd({
          object: {
            newPassword: this.psw2,
            originalPassword: this.psw,
            serviceType: "1",
            userId: this.userInfo.id,
          },
        })
        .then((res) => {
          if (res.data.code != "SUCCESS") throw Error(res.data.msg);
          this.Toast.success("修改支付密码成功");
          this.$router.go(-1);
        })
        .catch((e) => {
          const regex = /exceptionMsg=(.*?),/; // 匹配以exceptionMsg=开头，以逗号结尾

          const match = e.message.match(regex);
          let errorMessage = "";
          if (match) {
            const exceptionMsg = match[1];
            errorMessage = exceptionMsg;
          } else {
            const issue = /\bissue=([^,]+)/.exec(e.message)[1];
            errorMessage = issue;
          }
          this.Toast.fail(errorMessage);
        });
    },
    async resetPwd() {
      await this.$API.user
        .resetPwd({
          object: {
            balanceAcctId: this.electronicAcct[0].balanceAcctId,
            newPassword: this.psw2,
            serviceType: "1",
            smsCode: this.captcha,
            userId: this.userInfo.id,
          },
        })
        .then((res) => {
          if (res.data.code != "SUCCESS") throw Error(res.data.msg);
          this.Toast.success("修改支付密码成功");
          this.$router.go(-1);
        })
        .catch((e) => {
          const regex = /exceptionMsg=(.*?),/; // 匹配以exceptionMsg=开头，以逗号结尾

          const match = e.message.match(regex);
          let errorMessage = "";
          if (match) {
            const exceptionMsg = match[1];
            errorMessage = exceptionMsg;
          } else {
            const issue = /\bissue=([^,]+)/.exec(e.message)[1];
            errorMessage = issue;
          }
          this.Toast.fail(errorMessage);
        });
    },
    //  this.electronicAcct[0].balanceAcctId
  },
};
</script>

<style lang="scss" scoped>
.info {
  padding-top: 56px;
  background: #f5f5f5;
  display: flex;
  flex-direction: column;
  height: 100%;
  // .tips {
  //   color: #666666;
  //   padding: 12px 15px;
  //   line-height: 14px;
  // }
  main {
    flex: 1;
    background: #fff;
    .get_code {
      width: 76px;
      height: 24px;
      background: #ffffff;
      border-radius: 13px 13px 13px 13px;
      opacity: 1;
      border: 1px solid #0787f4;
      font-size: 12px;
      color: #0787f4;
    }
    .count_down {
      height: 24px;
      background: #f6f6f6;
      border-radius: 13px 13px 13px 13px;
      font-size: 12px;
      color: #999;
      padding: 0 8px;
    }
    .button {
      padding: 0 15px;
      ::v-deep .van-button {
        margin-top: 40px;
      }
    }
  }
}
</style>
